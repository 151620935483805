import React from 'react';

import {Params, useParams} from 'react-router-dom';

import { ISingleUseSignupSummary } from 'modules/single-use-signup/models';
import { readSingleUseSignupSummary } from 'modules/single-use-signup/api';

export const useSingleUseSignupSummaryFromUrl = (tenantId: number) => {
    const {singleUseSignupId: singleUseSignupIdPath} = useParams() as Params<'singleUseSignupId'>;
    const singleUseSignupId = parseInt(singleUseSignupIdPath as string);

    const [state, setState] = React.useState<{
        data: ISingleUseSignupSummary | null;
        loading: boolean;
        error: unknown;
    }>({
        data: null,
        loading: true,
        error: null,
    });

    React.useEffect(() => {
        (async () => {
            try {
                const result = await readSingleUseSignupSummary(tenantId, singleUseSignupId);
                setState({
                    data: result,
                    loading: false,
                    error: null,
                });
            } catch (error: unknown) {
                setState({
                    data: null,
                    loading: false,
                    error,
                });
            }
        })();
    }, [tenantId, singleUseSignupId]);

    return {
        singleUseSignupSummary: state.data,
        loading: state.loading,
        error: state.error,
    };
};
